import React from 'react';

import EPSQCalculatorComp from '../pages/EPSQCalculator';
import SenaComp from '../pages/Sena';
import SenaSettingsComp from '../pages/SenaSettings';
import IAppRoute from './AppRoute.type';
import routepaths from './routepaths';

const SENARoutes: IAppRoute[] = [
  { path: routepaths.sena, element: <SenaComp /> },
  { path: routepaths.senaSettings, element: <SenaSettingsComp /> },
  { path: routepaths.epsqCalculator, element: <EPSQCalculatorComp /> },
];

export default SENARoutes;
