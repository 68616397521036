import { FC, ReactNode, useCallback, useMemo } from 'react';

import { Icons, Loading, Table } from '@sede-x/shell-ds-react-framework';

import dayjs from 'dayjs';
import JSONPretty from 'react-json-pretty';

import Button from '../../../../UI/Button';
import Loader from '../../../../UI/Loader';
import { Modal } from '../../../../UI/Modal';
import useGetFieldAuditLogs from '../../../../hooks/UKOps/useGetFieldAuditLogs';
import {
  IFieldAuditLogs,
  IFieldType,
} from '../../../../types/UKNomination/FieldNomination.type';
import { AuditDetailWrapper, ModalFooter } from '../style';

const { InfoCircle: IconCircleInfo, ChevronDown, ChevronRight } = Icons;

interface FieldAuditLogsProps {
  open: boolean;
  onClose: () => void;
  url: string;
  children: ReactNode;
  fieldType: IFieldType;
  modalTitle?: string;
  origin?: string;
}

const FieldAuditLogs: FC<FieldAuditLogsProps> = ({
  open,
  onClose,
  modalTitle,
  url,
  fieldType,
  origin,
  children,
}) => {
  const {
    hasLoading,
    auditLogs,
    resetLogsOnCloseModal,
    onExpandLogs,
    onPayloadLogs,
  } = useGetFieldAuditLogs({
    url,
    hasLogs: open,
    fieldType,
    origin,
  });

  const handleOnClose = useCallback(() => {
    resetLogsOnCloseModal();
    onClose();
  }, [resetLogsOnCloseModal, onClose]);

  const renderFooter = useMemo(
    (): JSX.Element => (
      <ModalFooter>
        <Button variant='outlined' onClick={handleOnClose}>
          Close
        </Button>
      </ModalFooter>
    ),
    [handleOnClose]
  );

  const renderMessage = (item: IFieldAuditLogs): JSX.Element => {
    return (
      <>
        <div>
          <span>{item.note}</span>
          {fieldType === IFieldType.FIELD_NORMS && item?.payload && (
            <Button
              data-testid='info-button'
              size='small'
              className='logs-info'
              onClick={() => onPayloadLogs?.(item)}
              key={`info-btn-${item.id}`}
              icon={item.setaPayloadLoading ? <Loading /> : <IconCircleInfo />}
              iconOnly
            />
          )}
        </div>
        {item.expanded && (
          <div>
            {item.setaPayload && Object.keys(item.setaPayload).length > 0 && (
              <JSONPretty data={item.setaPayload} />
            )}
          </div>
        )}
      </>
    );
  };

  const renderExpandIcon = (item: IFieldAuditLogs): JSX.Element | null => {
    if (item?.setaPayload === null) return null;
    return (
      <Button
        iconOnly
        icon={item.expanded ? <ChevronDown /> : <ChevronRight />}
        size='small'
        variant='transparent'
        onClick={() => onExpandLogs(item.id)}
      />
    );
  };

  const columns = [
    {
      key: 'expanded',
      title: '',
      render: (item) => renderExpandIcon(item),
      width: 30,
    },
    {
      key: 'note',
      title: 'Message',
      render: renderMessage,
    },
    {
      dataIndex: 'createdDateTimeinGMT',
      key: 'createdDateTimeinGMT',
      title: 'Timestamp',
      width: 220,
      render: (createdDateTimeinGMT) => (
        <div>
          {`${dayjs(createdDateTimeinGMT).format('ddd DD-MMM-YY hh:mm:ss A')}`}
        </div>
      ),
    },
  ];

  return (
    <Modal
      open={open}
      onClose={handleOnClose}
      title={modalTitle}
      isDraggable
      maskClosable={false}
      footer={renderFooter}
      width={700}
    >
      <AuditDetailWrapper>
        {children}
        {hasLoading ? (
          <Loader />
        ) : (
          <Table
            columns={columns}
            data={auditLogs}
            size='small'
            rowKey={() => Math.random()}
          />
        )}
      </AuditDetailWrapper>
    </Modal>
  );
};

FieldAuditLogs.defaultProps = {
  modalTitle: 'Field Nomination Info and Audit Logs',
  origin: undefined,
};

export default FieldAuditLogs;
