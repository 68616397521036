import React from 'react';

import IBTMainComponent from '../pages/IBT';
import IAppRoute from './AppRoute.type';
import routePaths from './routepaths';

const IBTRoutes: IAppRoute[] = [
  {
    path: routePaths.ibtGas,
    element: <IBTMainComponent type='gas' />,
  },
];

export default IBTRoutes;
