import React from 'react';

import NetPosition from '../pages/NetPosition';
import NetPositionTabulated from '../pages/NetPositionTabulated';
import ScheduleRun from '../pages/ScheduleRun';
import Variable from '../pages/Variable';
import IAppRoute from './AppRoute.type';
import routepaths from './routepaths';

const FirstUtilityRoutes: IAppRoute[] = [
  {
    path: routepaths.scheduleRun,
    element: <ScheduleRun />,
  },
  {
    path: routepaths.netPositionTabulated,
    element: <NetPositionTabulated />,
  },
  {
    path: routepaths.variable,
    element: <Variable />,
  },
  {
    path: routepaths.netPosition,
    element: <NetPosition />,
  },
];

export default FirstUtilityRoutes;
