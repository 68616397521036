export interface IFieldNomination {
  id: number;
  emailTimeStamp: string;
  gasDay: string;
  sourceProductionFieldId: number;
  sourceProductionField: string;
  productionFieldId: number;
  productionField: string;
  terminal: string;
  energy: number | null;
  currentAligne: null | number;
  currentCodeRunner: null | string;
  alignStatus: string;
  source: string;
  formula: string | null;
  fromEmail: string | null;
  subject: string | null;
  origin: string;
  isLatest: boolean;
  isWDVarianceBreach: boolean;
  isDAVarianceBreach: boolean;
  zkeywithUnit: {
    unit: string;
    zkey: string;
  }[];
  cloneFlag?: boolean;
  editFlag?: boolean;
  isMatched: number;
  isValidClone: boolean;
}

export interface IEditFieldNomination {
  open: boolean;
  onClose: () => void;
  selectedItem: IFieldNomination | null;
  refreshData: () => void;
  selectedEnergy: string;
}

export interface IFieldNominationData {
  data: IFieldNomination[];
  totalRecords: number;
}

export interface IEmailCount {
  withinDay: number;
  dayAhead: number;
}

export interface IGroupingFieldNomination {
  productionField: string;
  data: IFieldNomination[];
  expanded: boolean;
}

export interface IGroupingFieldNominationData {
  data: IGroupingFieldNomination[];
  totalRecords: number;
  totalProductionField: number;
}

export interface ITerminalMapping {
  id: number;
  zKey: number;
  unit: string;
  productionFieldId: number;
  productionField: string;
  sourceProductionFieldId: number;
  sourceProductionField: string;
  terminalId: number;
  terminal: string;
  subTerminalId: number;
  subTerminal: string;
  formulaId: number;
  wdVarianceBreach: string;
  daVarianceBreach: string;
  timer: string | null;
  isMatched: number;
  validFrom: string;
  validTo: string;
  crContractReference: string;
  aligneSubTerminal: string;
}

export interface IUploadToAligne extends IFieldNomination {
  selectedZKey: string;
  unitOfMeasure: string;
  energyInMwh: number | null;
  updatedEnergy: number | null;
  comment: string;
  index: number;
  clonedCount: number;
  disableClone: boolean;
}

export interface IUploadToAligneContainer {
  open: boolean;
  onClose: () => void;
  refreshData: () => void;
  selectedUnit: string;
  data: IFieldNomination[];
}

export interface IUploadToAlignePayload {
  id: number;
  gasDay: string;
  unit: string;
  oldEnergy: number;
  newEnergy: number | null | undefined;
  zkey: string;
  comment: string;
}

export interface IAuditLogsContainer {
  open: boolean;
  onClose: () => void;
  selectedItem: IFieldNomination | null;
  renderEnergy: (energy: number | null) => void;
  selectedEnergy: string;
}

interface ISETAPayload {
  id: number;
  payloadType: string;
}

export interface IFieldAuditLogs {
  id: number;
  note: string;
  expanded: boolean;
  setaPayload: Record<string, unknown> | null;
  createdDateTimeinGMT: string;
  setaPayloadLoading?: boolean;
  payload?: ISETAPayload | null;
}

export enum IFieldType {
  FIELD_NORMS = 'field-norms',
  FIELD_MAPPING = 'field-mapping',
}

export interface GetFieldAuditLogsParams {
  url: string;
  hasLogs: boolean;
  fieldType: IFieldType;
  origin?: string | undefined;
}

export interface ILogsState {
  hasLoading: boolean;
  auditLogs: Array<IFieldAuditLogs>;
}
