import React from 'react';

import StoragePortfolioMainComp from '../pages/StoragePortfolio';
import IAppRoute from './AppRoute.type';
import routePaths from './routepaths';

const StoragePortfolioRoutes: IAppRoute[] = [
  {
    path: routePaths.storagePortfolioDashboard,
    element: <StoragePortfolioMainComp type='dashboard' />,
  },
  {
    path: routePaths.dealParameters,
    element: <StoragePortfolioMainComp type='deal-parameters' />,
  },
  {
    path: routePaths.facilityOverview,
    element: <StoragePortfolioMainComp type='facilityOverview' />,
  },
  {
    path: routePaths.facilityAttachment,
    element: <StoragePortfolioMainComp type='facilityAttachment' />,
  },
  {
    path: routePaths.dynamicTable,
    element: <StoragePortfolioMainComp type='dynamicTable' />,
  },
  {
    path: routePaths.facilityCompare,
    element: <StoragePortfolioMainComp type='facilityCompare' />,
  },
  {
    path: routePaths.dealFacilityAttachment,
    element: <StoragePortfolioMainComp type='dealFacilityAttachment' />,
  },
];

export default StoragePortfolioRoutes;
