import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localeData from 'dayjs/plugin/localeData';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import moment from 'moment';

import { COPApi } from '../services/COPApi';
import { ISelectOptions } from '../types/Common.type';
import { ITerminalMapping } from '../types/UKNomination/FieldNomination.type';

const TIME_ZONE = 'Europe/London';

dayjs.extend(utc);
dayjs.extend(weekday);
dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(localeData);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export interface MonthlyData {
  completedCount: number;
  cancelledCount: number;
  year: number;
  month: string;
}

export const getDblDigit = (n: string | number) => `0${n}`.slice(-2);

export const formattDate = (date: string, format = 'DD/MMM/YYYY') =>
  moment(date).format(format);

export const substringByWord = (
  str: string,
  length: number,
  addEllipsis = true
): string => {
  if (str.length < length) return str;
  let res = '';
  const words = str.split(' ');
  let endCheck = false;
  words.forEach((word) => {
    if (endCheck || (res + word).length > length) {
      endCheck = true;
      return;
    }
    if (res !== '') {
      res += ' ';
    }
    res += word;
  });
  return addEllipsis ? `${res}...` : res;
};

export const addSuffixToNumber = (n: number): string => {
  const j = n % 10;
  const k = n % 100;
  if (j === 1 && k !== 11) {
    return `${n}st`;
  }
  if (j === 2 && k !== 12) {
    return `${n}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${n}rd`;
  }
  return `${n}th`;
};

export const capitalizeFirstLetter = (str: string) =>
  str
    .split(' ')
    .map((word) => {
      if (word.length === 0) {
        return '';
      }
      if (word.length === 1) {
        return word.toUpperCase();
      }
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');

export const addDelimiterToNumber = (num: number) =>
  num.toLocaleString('en-US');

export function sortDataBasedOnMonth(data: MonthlyData[]) {
  data.sort((a, b) => {
    const monthOrder = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    return monthOrder.indexOf(a.month) - monthOrder.indexOf(b.month);
  });

  return data || [];
}

export function addCommasToNumber(number) {
  if (typeof number !== 'number') {
    return number;
  }

  if (Number.isNaN(number)) {
    return number;
  }

  if (!Number.isFinite(number)) {
    return 'Infinity';
  }

  // Use toLocaleString for formatting with commas
  return number.toLocaleString();
}

export const isLeapYear = (year) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

interface IGenerateOptions {
  recurringTypeId: number;
  month: number;
  year: number;
}

/**
 * Generate options based on the month, year, and recurringTypeId.
 * If recurringTypeId is 6, no need to check for leap year.
 * @param month - The month (1-12).
 * @param year - The year.
 * @param recurringTypeId - The recurring type ID.
 * @returns An array of options representing days in the month.
 */
export const generateOptions = ({
  month,
  year,
  recurringTypeId,
}: IGenerateOptions): number[] => {
  let daysInMonth = 31; // Default days in a month

  if (month === 2 && recurringTypeId !== 6) {
    // February: check for leap year unless recurringTypeId is 6
    daysInMonth = isLeapYear(year) ? 29 : 28;
  } else if ([4, 6, 9, 11].includes(month)) {
    // April, June, September, November: 30 days
    daysInMonth = 30;
  }

  let options: number[] = Array.from(
    { length: daysInMonth },
    (_, index) => index + 1
  );

  if (recurringTypeId === 7) {
    // Filter out weekends (Saturday and Sunday)
    options = options.filter((day) => {
      const date = new Date(year, month - 1, day);
      const dayOfWeek = date.getDay();
      return dayOfWeek !== 0 && dayOfWeek !== 6; // 0 is Sunday, 6 is Saturday
    });
  }

  return options;
};

export function getCurrentGasDay() {
  const londonTime = moment.tz(TIME_ZONE);

  if (londonTime.hour() < 5) {
    return dayjs(londonTime.subtract(1, 'day').toDate());
  }
  return dayjs(londonTime.toDate());
}

export const isFieldActive = (validFrom: string, validTo: string) => {
  const currentGasDay = dayjs(getCurrentGasDay()).startOf('day');

  const validFromDayjs = dayjs(validFrom).startOf('day');
  const validToDayjs = dayjs(validTo).startOf('day');
  return (
    currentGasDay.isSameOrAfter(validFromDayjs, 'day') &&
    currentGasDay.isSameOrBefore(validToDayjs, 'day')
  );
};

export const checkExisting = (
  keys: string[],
  existingData: ITerminalMapping[],
  formData: ITerminalMapping
): boolean => {
  return existingData.some((record) =>
    keys.every((key) => record[key] === formData[key])
  );
};

export const handleDownload = async (url: string, filename: string) => {
  const response = await COPApi({
    url,
    method: 'POST',
    responseType: 'blob',
  });
  const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = blobUrl;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

const filterFieldsByDate = (fields: ISelectOptions[], date: string) => {
  return fields.filter((field) => {
    if (!field.validFrom && !field.validTo) return true; // unmapped source prod field
    const fieldFromDate = dayjs(field.validFrom).startOf('day');
    const fieldToDate = dayjs(field.validTo).startOf('day');
    return (
      dayjs(date).isSame(fieldFromDate, 'day') ||
      dayjs(date).isSame(fieldToDate, 'day') ||
      dayjs(date).isBetween(fieldFromDate, fieldToDate, null, '[]')
    );
  });
};

export const filterFieldsByDateRange = (
  fields: ISelectOptions[],
  filterType: string,
  currentDate: string,
  nextDate: string,
  fromDate: string,
  toDate: string
) => {
  if (filterType === 'WD') {
    return filterFieldsByDate(fields, currentDate);
  }
  if (filterType === 'DA') {
    return filterFieldsByDate(fields, nextDate);
  }
  return fields.filter((field: ISelectOptions) => {
    if (!field.validFrom && !field.validTo) return true; // unmapped source prod field
    const fieldFromDate = dayjs(field.validFrom).startOf('day');
    const fieldToDate = dayjs(field.validTo).startOf('day');
    const selectedFromDate = dayjs(fromDate).startOf('day');
    const selectedToDate = dayjs(toDate).startOf('day');

    return (
      selectedFromDate.isSameOrAfter(fieldFromDate, 'day') &&
      selectedToDate.isSameOrBefore(fieldToDate, 'day')
    );
  });
};
