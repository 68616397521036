import React from 'react';

import { Button, Icons } from '@sede-x/shell-ds-react-framework';

import { ITerminalMapping } from '../../../../types/UKNomination/FieldNomination.type';

const { Edit, TrashAlt: DeleteIcon, InfoCircle } = Icons;

interface ActionButtonsProps {
  item: ITerminalMapping;
  onEdit: (item: ITerminalMapping) => void;
  onDelete: (item: ITerminalMapping) => void;
  onAudit: (item: ITerminalMapping) => void;
  isDisabled: boolean;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  item,
  onEdit,
  onDelete,
  onAudit,
  isDisabled,
}) => (
  <div className='grid-actions'>
    <Button
      iconOnly
      icon={<Edit />}
      variant='outlined'
      size='xsmall'
      title='Edit'
      onClick={() => onEdit(item)}
      disabled={isDisabled}
    />
    <Button
      iconOnly
      icon={<DeleteIcon />}
      variant='outlined'
      size='xsmall'
      title='Delete'
      onClick={() => onDelete(item)}
      disabled={isDisabled}
    />
    <Button
      iconOnly
      icon={<InfoCircle />}
      variant='outlined'
      size='xsmall'
      title='Audit Logs'
      onClick={() => onAudit(item)}
      disabled={isDisabled}
    />
  </div>
);

export default ActionButtons;
