import React from 'react';

import BeachSwapsMainComponent from '../pages/BeachSwaps';
import IAppRoute from './AppRoute.type';
import routePaths from './routepaths';

const BeachSwapsRoutes: IAppRoute[] = [
  {
    path: routePaths.beachSwaps,
    element: <BeachSwapsMainComponent type='beachSwap' />,
  },
  {
    path: routePaths.coastKeyMapper,
    element: <BeachSwapsMainComponent type='coastKeyMapper' />,
  },
];

export default BeachSwapsRoutes;
