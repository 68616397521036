export const GET_FIELD_NOMINATIONS =
  '/api/cop/UKNomination/GetUKNominationdata';
export const DOWNLOAD_FIELD_NOMINATIONS =
  '/api/cop/UKNomination/DownloadUKNominationExcel';
export const UPDATE_FIELD_NOMINATIONS =
  '/api/cop/UKNomination/UpdateUKNomination';
export const GET_TERMINAL_MAPPINGS = (isValid: boolean) =>
  `/api/cop/UKNomination/GetProdFieldTerminalMappings?isValid=${isValid}`;
export const ADD_UPDATE_TERMINAL_MAPPINGS =
  '/api/cop/UKNomination/AddorUpdateTerminalMapping';
export const DELETE_TERMINAL_MAPPINGS =
  '/api/cop/UKNomination/DeleteTerminalMapping';
export const DOWNLOAD_TERMINAL_MAPPINGS =
  '/api/cop/UKNomination/DownloadTerminalMappingExcel';
export const GET_PRODUCTION_FIELDS =
  '/api/cop/UKNomination/GetAllProductionFielddata';
export const GET_TERMINALS = '/api/cop/UKNomination/GetAllTerminal';
export const GET_SUB_TERMINALS = '/api/cop/UKNomination/GetAllSubTerminal';
export const GET_SOURCE_PRODUCTION_FIELDS =
  '/api/cop/UKNomination/GetSourceProductionFielddata';
export const POST_UPLOAD_TO_ALIGNE = '/api/cop/UKNomination/UploadtoAligne';
export const GET_NOMINATION_DETAILS =
  '/api/cop/UKNomination/GetDetailsForNomination';
export const POST_NOMINATION = '/api/cop/UKNomination/AddNominations';
export const GET_ALIGNE_PAYLOAD = '/api/cop/UKNomination/GetPayloadInfo';
export const UPDATE_NOMINATION_STATUS_TO_SUBMITTED =
  '/api/cop/UKNomination/UpdateNominationStatus';
export const GET_CURRENT_ALIGNE =
  '/api/cop/UKNomination/LoadAlignVolumeDataInDB';
export const FIELD_NOMS_DEADLINE_NOTIFICATION =
  '/api/cop/UKNomination/GetAllNotification';
export const FETCH_ALIGNE_STATUS = '/api/cop/UKNomination/FetchAligneStatus';

export const UPDATE_ADHOC_ALIGNE_CR_LOADING = `/api/cop/UKNomination/UpdateAdhocAligneCrLoadSwing`;

export const GET_UNMAPPED_SOURCE_PRODUCTION_FIELDS = `/api/cop/UKNomination/GetUnmappedSourceProductionFields`;
export const UPDATE_NOMINATION_VISIBILITY = `/api/cop/UKNomination/UpdateNominationVisibility`;

export const ACKNOWLEDGE_NOMINATION = `/api/cop/UKNomination/AcknowledgeNomination`;
export const GET_UK_NOMINATION_LOGS = `/api/cop/UKNomination/GetMappingAuditLog`;

export const MESSAGES = {
  SERVER_ERROR: 'Something went wrong please contact system administrator.',
  REQUIRED_ENERGY: 'Energy is required',
  REQUIRED_Z_KEY: 'zKey is required',
  INVALID_Z_KEY: 'Please enter a valid zKey, it should only contain numbers',
  REQUIRED_UNIT: 'Unit is required',
  REQUIRED_PRODUCTION_FIELD: 'Production Field is required',
  REQUIRED_SOURCE_PRODUCTION_FIELD: 'Source Production Field is required',
  REQUIRED_TERMINAL: 'Terminal is required',
  REQUIRED_SUB_TERMINAL: 'SubTerminal is required',
  REQUIRED_GAS_DAY: 'Gas Day is required',
  WD_DA_VARIANCE_RANGE: 'Value must be between 0 and 100',
  MAPPING_OVERRIDE:
    'Please note that when you addorupdate mapping, if the fields Deadline, Formula, WD Variance Breach, and DA Variance Breach are present for another mapping with the same Source Production Field, those values will be overridden.',
};
