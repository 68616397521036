import { useCallback, useEffect, useMemo, useState } from 'react';

import { useToast } from '../../UI/Toast';
import {
  GET_ALIGNE_PAYLOAD,
  MESSAGES,
} from '../../constants/UKNomination/FieldNomination';
import { get } from '../../services/COPApi';
import {
  GetFieldAuditLogsParams,
  IFieldAuditLogs,
  IFieldType,
  ILogsState,
} from '../../types/UKNomination/FieldNomination.type';

type GetFieldAuditLogsType = ({
  url,
  hasLogs,
  fieldType,
  origin,
}: GetFieldAuditLogsParams) => ILogsState & {
  resetLogsOnCloseModal: () => void;
  onExpandLogs: (id: number) => void;
  onPayloadLogs?: (auditLog: IFieldAuditLogs) => void;
};

const useGetFieldAuditLogs: GetFieldAuditLogsType = ({
  url,
  hasLogs = false,
  fieldType,
  origin,
}: GetFieldAuditLogsParams) => {
  const initialAuditLogs = {
    hasLoading: false,
    auditLogs: [],
  };
  const toast = useToast();
  const [{ hasLoading, auditLogs }, setAuditLogs] =
    useState<ILogsState>(initialAuditLogs);

  const handleResetAuditLogsOnClose = () => {
    setAuditLogs((prevState) => ({
      ...prevState,
      auditLogs: [],
    }));
  };

  const handleExpandCollapseOnClick = useCallback(
    (id: number) => {
      setAuditLogs((prevState) => ({
        ...prevState,
        auditLogs: prevState.auditLogs.map((log) => {
          if (log.id === id) {
            return {
              ...log,
              expanded: !log.expanded,
            };
          }
          return log;
        }),
      }));
    },
    [setAuditLogs]
  );

  const getAuditLogs = useCallback(
    (apiUrl: string) => {
      setAuditLogs((prevState) => ({
        ...prevState,
        hasLoading: true,
      }));
      get(apiUrl)
        .then((response) => {
          const { data = [] } = response;
          setAuditLogs((prevState) => ({
            ...prevState,
            auditLogs: data.map((log) => {
              return {
                id: log.id,
                note: log.note,
                ...(fieldType === IFieldType.FIELD_NORMS
                  ? {
                      setaPayload: null,
                      createdDateTimeinGMT: log.createdDateTimeinGMT,
                      payload: log.payload,
                    }
                  : {
                      setaPayload: log.auditLog,
                      createdDateTimeinGMT: log.modifiedDate,
                    }),
              };
            }),
          }));
        })
        .catch(() => {
          toast?.open(MESSAGES.SERVER_ERROR, 'error');
        })
        .finally(() => {
          setAuditLogs((prevState) => ({
            ...prevState,
            hasLoading: false,
          }));
        });
    },
    [fieldType, toast]
  );

  const getFieldFormsPayloadLogs = (auditLog: IFieldAuditLogs) => {
    get(
      `${GET_ALIGNE_PAYLOAD}?trackerId=${auditLog.payload?.id}&payloadType=${auditLog.payload?.payloadType}&origin=${origin}`
    )
      .then((response) => {
        setAuditLogs((prevState) => ({
          ...prevState,
          auditLogs: prevState.auditLogs.map((log) => {
            if (log.id === auditLog.id) {
              return {
                ...log,
                expanded: true,
                setaPayloadLoading: false,
                setaPayload: response?.data || {},
              };
            }
            return log;
          }),
        }));
      })
      .catch(() => {
        toast?.open(MESSAGES.SERVER_ERROR, 'error');
        setAuditLogs((prevState) => ({
          ...prevState,
          auditLogs: prevState.auditLogs.map((log) => ({
            ...log,
            setaPayloadLoading: false,
          })),
        }));
      });
  };

  const handleOnOpenPayload = (auditLog: IFieldAuditLogs) => {
    if (auditLog.setaPayload && !auditLog.expanded)
      handleExpandCollapseOnClick(auditLog.id);
    if (auditLog.setaPayload) return;
    setAuditLogs((prevState) => ({
      ...prevState,
      auditLogs: prevState.auditLogs.map((log) => {
        if (log.id === auditLog.id) {
          return {
            ...log,
            setaPayloadLoading: true,
            expanded: true,
          };
        }
        return log;
      }),
    }));
    getFieldFormsPayloadLogs(auditLog);
  };

  const sortedAuditLogsWithLatestLogs = useMemo(() => {
    return auditLogs.sort((logA, logB) => logB.id - logA.id);
  }, [auditLogs]);

  useEffect(() => {
    if (hasLogs) {
      getAuditLogs(url);
    }
  }, [hasLogs, url, getAuditLogs]);

  return {
    hasLoading,
    auditLogs: sortedAuditLogsWithLatestLogs,
    resetLogsOnCloseModal: handleResetAuditLogsOnClose,
    onExpandLogs: handleExpandCollapseOnClick,
    onPayloadLogs: handleOnOpenPayload,
  };
};

export default useGetFieldAuditLogs;
