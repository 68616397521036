/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { IFormula, getFormula } from '../../store/UKOps/FieldNoms';
import { FullStateI } from '../../types/All.type';

type GetFormulaType = () => {
  loadingFormula: boolean;
  formulas: IFormula[];
};

const useGetFormulas: GetFormulaType = () => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(
    (state: FullStateI) => state.FieldNoms.formulaData
  );

  useEffect(() => {
    if (!error) {
      dispatch(getFormula());
    }
  }, []);

  return { loadingFormula: loading, formulas: data };
};

export default useGetFormulas;
