import React from 'react';

import PrismaCalendar from '../pages/PrismaCalendar';
import IAppRoute from './AppRoute.type';
import routepaths from './routepaths';

const CMTRoutes: IAppRoute[] = [
  { path: routepaths.prismaCalendar, element: <PrismaCalendar /> },
  { path: routepaths.auctionDetails, element: <PrismaCalendar /> },
];

export default CMTRoutes;
