import React from 'react';

import CapacityVolumeBookedHistory from '../components/ChartsComp/CapacityVolumeBookedHistory';
import VolumeImbalanceHistory from '../components/ChartsComp/VolumeImbalanceHistory';
import CMTApplicationStatusHistory from '../pages/charts/CMTApplicationStatusHistory';
import TradedGasVolumeHistory from '../pages/charts/TradedGasVolumeHistory';
import IAppRoute from './AppRoute.type';
import routepaths from './routepaths';

const ChartsRoutes: IAppRoute[] = [
  {
    path: routepaths.chartsTradedGasVolume,
    element: <TradedGasVolumeHistory />,
  },

  {
    path: routepaths.cmtApplicationStatus,
    element: <CMTApplicationStatusHistory />,
  },

  {
    path: routepaths.chartsCapacityVolumeBooked,
    element: <CapacityVolumeBookedHistory />,
  },
  {
    path: routepaths.chartsVolumeImbalance,
    element: <VolumeImbalanceHistory />,
  },
];

export default ChartsRoutes;
