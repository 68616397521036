/* eslint-disable react-hooks/exhaustive-deps, react/require-default-props */
import React, { useEffect, useState } from 'react';

import {
  Option,
  Pagination,
  Select,
  Table as ShellTable,
} from '@sede-x/shell-ds-react-framework';

import Loader from '../Loader';
import ColumnTitle from './ColumnTitle';
import { LoaderContainer, TableContainer } from './style';

interface ITable {
  isLoading?: boolean;
  data: Array<any>;
  columns: Array<any>;
}

interface ISorting {
  field: string;
  order: string | null;
  nestedObject?: string | undefined;
}

const Table: React.FC<ITable> = ({
  isLoading = false,
  data = [],
  columns = [],
}) => {
  const [allTableData, setAllTableData] = useState<Array<any>>([]);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [currentPageSize, setCurrentPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCounts, setTotalCounts] = useState<number>(0);
  const paginationOptions: number[] = [5, 10, 20, 50, 100, 250];
  const [sortedColumn, setSortedColumn] = useState<ISorting>({
    field: '',
    order: null,
  });

  const handlePaginationChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    const objData = allTableData.slice((page - 1) * pageSize, page * pageSize);
    setTableData(objData);
  };

  const sortingData = (
    itemsCollection: Array<any>,
    order: string | null,
    key: string
  ) => {
    return [...itemsCollection].sort((a, b) => {
      if (a[key] > b[key]) {
        return order === 'asc' ? 1 : -1;
      }
      if (a[key] < b[key]) {
        return order === 'desc' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSort = (
    key: string,
    order: string | null,
    nestedObject: string | undefined
  ) => {
    const sortedObject: ISorting = {
      field: order === null ? '' : key,
      order,
      nestedObject,
    };
    setSortedColumn(sortedObject);
    if (order) {
      setAllTableData(sortingData([...allTableData], order, key));
    } else {
      setAllTableData([...data]);
    }
  };

  const shellColumns = columns.map((column) => {
    return {
      ...column,
      title: (
        <ColumnTitle
          column={column}
          sortedColumn={sortedColumn}
          handleSort={handleSort}
        />
      ),
    };
  });

  const handlePageSizeChange = (value) => {
    setCurrentPage(1);
    setCurrentPageSize(value);
  };

  useEffect(() => {
    const numberOfPages = Math.ceil(allTableData.length / currentPageSize);
    handlePaginationChange(
      numberOfPages >= currentPage ? currentPage : 1,
      currentPageSize
    );
  }, [allTableData, currentPageSize]);

  useEffect(() => {
    setTotalCounts(data.length);
    if (sortedColumn.field !== '') {
      const sortedData = sortingData(
        data,
        sortedColumn.order,
        sortedColumn.field
      );
      setAllTableData(sortedData);
      return;
    }
    setAllTableData(data);
  }, [currentPageSize, data]);

  if (totalCounts === 0 && isLoading) {
    return <Loader />;
  }

  return (
    <TableContainer>
      {isLoading && (
        <LoaderContainer>
          <div data-testid='loader' className='loader-wrapper'>
            <Loader />
          </div>
        </LoaderContainer>
      )}
      <ShellTable
        data={tableData}
        columns={shellColumns}
        prefixCls='shell-table'
        size='medium'
        data-testid='table'
        rowKey={() => Math.random()}
      />
      {data.length > 0 && (
        <div className='pagination-container'>
          <div className='total-rows'>{`Total Rows: ${totalCounts}`}</div>
          <Pagination
            onChange={handlePaginationChange}
            current={currentPage}
            pageSize={currentPageSize}
            total={totalCounts}
            position='right'
            data-testid='pagination'
            size='small'
          />
          <Select
            placeholder='Page Size'
            showSearch={false}
            onChange={handlePageSizeChange}
            value={currentPageSize}
            className='select-page-size'
            data-testid='page-size'
            allowClear={false}
            size='small'
          >
            {paginationOptions.map((item) => {
              return (
                <Option key={`pagination_opt_${item}`} value={item}>
                  {item}
                </Option>
              );
            })}
          </Select>
        </div>
      )}
    </TableContainer>
  );
};

export default Table;
