/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect, useState } from 'react';

import {
  Checkbox,
  Heading,
  Icons,
  Loading,
  TextInput,
} from '@sede-x/shell-ds-react-framework';

import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from '../../../../UI/Button';
import Card from '../../../../UI/Card';
import { Col, FormRow, Row } from '../../../../UI/Grid';
import Loader from '../../../../UI/Loader';
import Table from '../../../../UI/Table';
import { useToast } from '../../../../UI/Toast';
import { applicationName, userRole } from '../../../../constants/Auth';
import {
  DOWNLOAD_TERMINAL_MAPPINGS,
  GET_TERMINAL_MAPPINGS,
  GET_UK_NOMINATION_LOGS,
  MESSAGES,
  UPDATE_NOMINATION_VISIBILITY,
} from '../../../../constants/UKNomination/FieldNomination';
import { get, put } from '../../../../services/COPApi';
import { FieldNomsActions } from '../../../../store/UKOps/FieldNoms';
import { FullStateI } from '../../../../types/All.type';
import {
  IFieldType,
  ITerminalMapping,
} from '../../../../types/UKNomination/FieldNomination.type';
import {
  checkExisting,
  handleDownload,
  isFieldActive,
} from '../../../../utils/general';
import searchByText from '../../../../utils/searchFunction';
import AddEditTerminalMapping from '../AddEditTerminalMapping';
import FieldAuditLogs from '../FieldAuditLogs';
import UnMappedMapping from '../UnmappedTerminals';
import { FieldNominationWrapper, TableLoaderContainer } from '../style';
import ActionButtons from './ActionButtons';
import ConfirmDeleteMapping from './ConfirmDeleteMapping';

const { DownloadSolid, Cross } = Icons;

const TerminalMapping: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userRole: userAccess } = useSelector(
    (state: FullStateI) => state.User
  );

  const [open, setOpen] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openUnMapped, setOpenUnMapped] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [data, setData] = useState<ITerminalMapping[]>([]);
  const [isValidContracts, setIsValidContracts] = useState<boolean>(true);
  const [openAudit, setOpenAudit] = useState(false);

  const [selectedItem, setSelectedItem] = useState<ITerminalMapping | null>(
    null
  );
  const [loadingIsVisible, setLoadingIsVisible] = useState<{
    [key: string]: boolean;
  }>({});

  const getTerminalMappings = (isValid: boolean) => {
    setLoading(true);
    get(GET_TERMINAL_MAPPINGS(isValid))
      .then((response) => {
        setData(response?.data || []);
      })
      .catch(() => {
        toast?.open(MESSAGES.SERVER_ERROR, 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getTerminalMappings(isValidContracts);
  }, [isValidContracts]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleUpdateProdFields = (
    prodFieldId: number,
    sourceProdFieldId: number,
    status: boolean
  ) => {
    dispatch(
      FieldNomsActions.updateProdFields({
        prodFieldId,
        sourceProdFieldId,
        status,
      })
    );
  };

  const handleOnOpenClose = (value: boolean) => {
    setOpen(value);
    if (!value) {
      setSelectedItem(null);
    }
  };

  const handleOnOpenCloseConfirm = (value: boolean) => {
    setOpenConfirm(value);
    if (!value) {
      setSelectedItem(null);
    }
  };

  const handleOnOpenCloseAudit = (value: boolean) => {
    setOpenAudit(value);
    if (!value) {
      setSelectedItem(null);
    }
  };

  const handleDownloadOnClick = async () => {
    setDownloading(true);
    await handleDownload(
      DOWNLOAD_TERMINAL_MAPPINGS,
      `Mappings_${dayjs().format()}.xlsx`
    );
    setDownloading(false);
  };

  const handleEditOnClick = (item: ITerminalMapping) => {
    setSelectedItem(item);
    handleOnOpenClose(true);
  };

  const handleDeleteOnClick = (item: ITerminalMapping) => {
    setSelectedItem(item);
    handleOnOpenCloseConfirm(true);
  };

  const handleInfoOnClick = (item: ITerminalMapping) => {
    setSelectedItem(item);
    handleOnOpenCloseAudit(true);
  };

  const renderData = (text: string, mappings: ITerminalMapping[]) => {
    return searchByText(mappings, text, [
      'zKey',
      'unit',
      'sourceProductionField',
      'productionField',
      'terminal',
      'subTerminal',
      'timer',
      'formula',
      'daVarianceBreach',
      'wdVarianceBreach',
    ]);
  };

  const handleIsVisible = ({
    productionFieldId,
    sourceProductionFieldId,
    isVisible: checked,
    id,
  }: {
    productionFieldId: number;
    sourceProductionFieldId: number;
    isVisible: boolean;
    id: number;
  }) => {
    setLoadingIsVisible((prevState) => ({
      ...prevState,
      [sourceProductionFieldId]: true,
    }));
    put(
      `${UPDATE_NOMINATION_VISIBILITY}?sourceProductionFieldId=${sourceProductionFieldId}&isVisible=${checked}&fieldMappingId=${id}`,
      {}
    )
      .then((response) => {
        toast?.open(response?.data, 'success');
        handleUpdateProdFields(
          productionFieldId,
          sourceProductionFieldId,
          checked
        );
        setLoadingIsVisible((prevState) => ({
          ...prevState,
          [sourceProductionFieldId]: false,
        }));
        setData((prevState) => {
          return prevState.map((item) => {
            if (item.sourceProductionFieldId === sourceProductionFieldId) {
              return {
                ...item,
                isVisible: checked,
              };
            }
            return item;
          });
        });
      })
      .catch(() => {
        setLoadingIsVisible((prevState) => ({
          ...prevState,
          [sourceProductionFieldId]: false,
        }));
        toast?.open(MESSAGES.SERVER_ERROR, 'error');
      });
  };

  const checkTerminalMappingExists = (frmData) => {
    const formData: ITerminalMapping = {
      ...frmData,
      zKey: frmData.zKey ? parseFloat(frmData.zKey) : frmData.zKey,
    };
    let objData = data;
    if (selectedItem) {
      objData = data.filter((item) => item.id !== selectedItem.id);
    }
    const mappingExists: boolean = checkExisting(
      [
        'zKey',
        'unit',
        'sourceProductionFieldId',
        'productionFieldId',
        'terminalId',
        'subTerminalId',
      ],
      objData,
      formData
    );
    if (mappingExists) {
      return {
        error: true,
        message:
          'A Production Field and terminal can be mapped to only one zKey',
      };
    }
    return {
      error: false,
      message: '',
    };
  };

  const sourceProductionFieldExists = (sourceProductionFieldId: number) => {
    const result = data.filter(
      (_) => _.sourceProductionFieldId === sourceProductionFieldId
    );
    if (selectedItem) return result.length > 1;
    return result.length > 0;
  };

  const columns = [
    {
      title: '',
      width: 20,
      render: (row) => {
        const isActive = isFieldActive(row?.validFrom, row?.validTo);
        return (
          <img
            width={20}
            height={20}
            src={isActive ? '/check-green.png' : '/cross-red.png'}
            alt={isActive ? 'Active' : 'Inactive'}
            title={isActive ? 'Active' : 'Inactive'}
          />
        );
      },
    },
    {
      dataIndex: 'zKey',
      key: 'zKey',
      title: 'zKey',
      sorting: true,
    },
    {
      dataIndex: 'unit',
      key: 'unit',
      title: 'Deal Unit',
      sorting: true,
    },
    {
      dataIndex: 'sourceProductionField',
      key: 'sourceProductionField',
      title: 'Source Production Field',
      sorting: true,
    },
    {
      dataIndex: 'productionField',
      key: 'productionField',
      title: 'Production Field',
      sorting: true,
    },
    {
      dataIndex: 'terminal',
      key: 'terminal',
      title: 'Terminal',
      sorting: true,
    },
    {
      dataIndex: 'subTerminal',
      key: 'subTerminal',
      title: 'Sub Terminal',
      sorting: true,
    },
    {
      dataIndex: 'timer',
      key: 'timer',
      title: 'Deadline Time',
    },
    {
      dataIndex: 'formula',
      key: 'formula',
      title: 'Formula',
    },
    {
      key: 'Source',
      title: 'Source',
      width: 100,
      render: (row) =>
        row?.dataSource?.length > 0 ? row?.dataSource?.join(', ') : 'N/A',
    },
    {
      dataIndex: 'crContractReference',
      key: 'crContractReference',
      title: 'CR Reference',
    },
    {
      dataIndex: 'aligneSubTerminal',
      key: 'aligneSubTerminal',
      title: 'Aligne Location Name',
    },
    {
      dataIndex: 'wdVarianceBreach',
      key: 'wdVarianceBreach',
      title: 'WD Variance Breach',
      sorting: true,
    },
    {
      dataIndex: 'daVarianceBreach',
      key: 'daVarianceBreach',
      title: 'DA Variance Breach',
      sorting: true,
    },
    {
      dataIndex: '',
      title: 'Is Visible',
      render: (row) =>
        loadingIsVisible[row.sourceProductionFieldId] ? (
          <Loading />
        ) : (
          <Checkbox
            disabled={
              !(userAccess[applicationName.genesisAdmin] === userRole.readWrite)
            }
            checked={row?.isVisible}
            onChange={() =>
              handleIsVisible({
                productionFieldId: row?.productionFieldId,
                sourceProductionFieldId: row?.sourceProductionFieldId,
                isVisible: !row?.isVisible,
                id: row?.id,
              })
            }
          />
        ),
    },
    {
      title: 'Valid From',
      render: (row) => dayjs(row.validFrom).format('DD MMM YYYY'),
    },
    {
      title: 'Valid To',
      render: (row) => dayjs(row.validTo).format('DD MMM YYYY'),
    },
    {
      title: 'Actions',
      render: (row) => (
        <ActionButtons
          item={row}
          onEdit={handleEditOnClick}
          onDelete={handleDeleteOnClick}
          onAudit={handleInfoOnClick}
          isDisabled={
            !(userAccess[applicationName.genesisAdmin] === userRole.readWrite)
          }
        />
      ),
    },
  ];

  return (
    <FieldNominationWrapper>
      <Card>
        <Row>
          <Col size={12}>
            <FormRow>
              <Col size={10}>
                <Heading type='h2'>
                  Production Field - zKey - Terminal - SubTerminal mapping
                </Heading>
              </Col>
              <Col size={2}>
                <Button
                  className='close-mapping'
                  iconOnly
                  icon={<Cross />}
                  onClick={() => navigate(-1)}
                  variant='transparent'
                />
              </Col>
            </FormRow>
            <FormRow className='filter-container'>
              <Col size={6} className='date-range'>
                <TextInput
                  size='small'
                  placeholder='Search'
                  onChange={handleSearch}
                />
                <Checkbox
                  size='large'
                  label='Valid Mappings'
                  name='Show Valid Mapping'
                  checked={isValidContracts}
                  onChange={() => setIsValidContracts(!isValidContracts)}
                />
              </Col>
              <Col size={6} className='button-wrapper'>
                <Button
                  size='small'
                  onClick={() => handleOnOpenClose(true)}
                  disabled={
                    !(
                      userAccess[applicationName.genesisAdmin] ===
                      userRole.readWrite
                    )
                  }
                >
                  Add New
                </Button>
                <Button onClick={() => setOpenUnMapped(true)}>UnMapped</Button>
                <Button
                  size='small'
                  icon={<DownloadSolid />}
                  onClick={handleDownloadOnClick}
                  loading={downloading}
                  disabled={downloading}
                >
                  Download
                </Button>
              </Col>
            </FormRow>
            <FormRow className='table-container'>
              {loading && (
                <TableLoaderContainer>
                  <div data-testid='loader' className='loader-wrapper'>
                    <Loader />
                  </div>
                </TableLoaderContainer>
              )}
              <Col size={12}>
                <Table columns={columns} data={renderData(searchText, data)} />
              </Col>
            </FormRow>
          </Col>
        </Row>
      </Card>
      <AddEditTerminalMapping
        open={open}
        selectedItem={selectedItem}
        onClose={() => handleOnOpenClose(false)}
        refreshData={() => getTerminalMappings(isValidContracts)}
        checkExisting={checkTerminalMappingExists}
        sourceProductionFieldExists={sourceProductionFieldExists}
      />
      <ConfirmDeleteMapping
        open={openConfirm}
        selectedItem={selectedItem}
        onClose={() => handleOnOpenCloseConfirm(false)}
        refreshData={() => getTerminalMappings(isValidContracts)}
      />
      <UnMappedMapping
        open={openUnMapped}
        onClose={() => setOpenUnMapped(false)}
      />

      {openAudit && (
        <FieldAuditLogs
          open={openAudit}
          url={`${GET_UK_NOMINATION_LOGS}?mappingId=${selectedItem?.id}`}
          onClose={() => handleOnOpenCloseAudit(false)}
          modalTitle='Field Mapping Nomination Info and Audit Logs'
          fieldType={IFieldType.FIELD_MAPPING}
        >
          <>
            <div>
              <span className='title'>Source production Field :</span>{' '}
              {selectedItem?.sourceProductionField}
            </div>
            <div>
              <span className='title'>Production Field :</span>{' '}
              {selectedItem?.productionField}
            </div>
            <div>
              <span className='title'>Sub Terminal :</span>{' '}
              {selectedItem?.subTerminal}
            </div>
            <div>
              <span className='title'>Audit Logs:</span>
            </div>
          </>
        </FieldAuditLogs>
      )}
    </FieldNominationWrapper>
  );
};

export default TerminalMapping;
