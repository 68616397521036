import React from 'react';

import PnLDashboardInputComp from '../pages/PnLDashboard/InputPnLDashboard';
import PnLDashboardViewComp from '../pages/PnLDashboard/ViewPnLDashboard';
import IAppRoute from './AppRoute.type';
import routepaths from './routepaths';

const PNLRoutes: IAppRoute[] = [
  {
    path: routepaths.pnlDashboardView,
    element: <PnLDashboardViewComp />,
  },
  {
    path: routepaths.pnlDashboardInput,
    element: <PnLDashboardInputComp />,
  },
];

export default PNLRoutes;
