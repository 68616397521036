import React, { useCallback, useEffect } from 'react';

import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import routepaths from '../router/routepaths';
import { userRoleActions } from '../store/User';
import { UserInfoI } from '../types/UserState.type';
import getAccessTokenFromCode from '../utils/auth/getAccessTokenFromCode';
import getAccessTokenFromCodeRefreshToken from '../utils/auth/getAccessTokenFromCodeRefreshToken';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchUserInfo = useCallback(
    async (code: string | undefined) => {
      const { user, accessToken, error } = await getAccessTokenFromCode(code);
      if (error) {
        navigate(routepaths.login);
      } else if (user) {
        const userObj = {
          sn: user.sn,
          givenName: user.givenName,
          uid: user.uid,
          accessToken,
          mail: user.mail,
        };
        dispatch(userRoleActions.setNameAccessTocken(userObj));
        navigate(routepaths.authenticate);
      }
    },
    [navigate, dispatch]
  );

  const refreshAccessToken = useCallback(async () => {
    const refreshToken = sessionStorage.getItem('refresh_token');
    const { user, accessToken, error } =
      await getAccessTokenFromCodeRefreshToken(refreshToken || '');
    if (error) {
      navigate(routepaths.login);
    } else if (user) {
      const userObj = {
        sn: user.sn,
        givenName: user.givenName,
        uid: user.uid,
        accessToken,
        mail: user.mail,
      };

      dispatch(userRoleActions.setNameAccessTocken(userObj));
      navigate(routepaths.authenticate);
    }
  }, [navigate, dispatch]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.get('code') && searchParams.get('state')) {
      const code = searchParams.get('code') || undefined;
      fetchUserInfo(code);
    } else {
      const accessToken = sessionStorage.getItem('access_token');
      const expiryTime = sessionStorage.getItem('expiry_time');
      if (accessToken && expiryTime) {
        const exp = moment.unix(parseInt(expiryTime, 10));
        if (exp.isAfter(moment())) {
          const decodedToken = jwtDecode<UserInfoI>(accessToken);
          const userObj = {
            sn: decodedToken.sn,
            givenName: decodedToken.givenName,
            uid: decodedToken.uid,
            accessToken,
            mail: decodedToken.mail,
          };
          dispatch(userRoleActions.setNameAccessTocken(userObj));

          navigate(routepaths.authenticate);
        } else {
          refreshAccessToken();
        }
      } else {
        navigate(routepaths.login);
      }
    }
  }, [dispatch, navigate, refreshAccessToken, fetchUserInfo]);
  return null;
};

export default Home;
