import React, { useState } from 'react';

import { Divider, Text } from '@sede-x/shell-ds-react-framework';

import styled from 'styled-components';

import Button from '../../../../UI/Button';
import { Modal } from '../../../../UI/Modal';
import { useToast } from '../../../../UI/Toast';
import {
  DELETE_TERMINAL_MAPPINGS,
  MESSAGES,
} from '../../../../constants/UKNomination/FieldNomination';
import { deleteApi } from '../../../../services/COPApi';
import { ITerminalMapping } from '../../../../types/UKNomination/FieldNomination.type';
import { ModalFooter } from '../style';

const StyledContainer = styled.div`
  text-align: left;
`;

const StyledText = styled(Text)`
  color: #8f7630;
  font-weight: bold;
  margin-bottom: 10px;
`;

const StyledDetail = styled.div`
  margin-top: 10px;
`;

interface IDeleteMapping {
  open: boolean;
  onClose: () => void;
  selectedItem: ITerminalMapping | null;
  refreshData: () => void;
}

const ConfirmDeleteMapping: React.FC<IDeleteMapping> = ({
  selectedItem,
  open,
  onClose = () => {},
  refreshData = () => {},
}) => {
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(false);

  const deleteMapping = () => {
    setLoading(true);
    deleteApi(`${DELETE_TERMINAL_MAPPINGS}?id=${selectedItem?.id}`, {})
      .then(() => {
        toast?.open(`Mapping Deleted Successfully!`, 'success');
        onClose();
        refreshData();
      })
      .catch(() => {
        toast?.open(MESSAGES.SERVER_ERROR, 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderFooter = () => (
    <ModalFooter>
      <Button onClick={deleteMapping} loading={loading} disabled={loading}>
        Yes
      </Button>
      <Button variant='outlined' onClick={onClose}>
        Cancel
      </Button>
    </ModalFooter>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Delete Mapping'
      isDraggable
      maskClosable={false}
      footer={renderFooter()}
      width='40%'
    >
      {selectedItem && (
        <StyledContainer>
          <StyledText>
            Do you really want to delete this mapping? We recommend not to
            delete it if actual nomination records are linked to it.
          </StyledText>
          <Divider />
          <StyledDetail>
            <b>zKey:</b> {selectedItem?.zKey}
          </StyledDetail>
          <StyledDetail>
            <b>Production Field:</b> {selectedItem?.productionField}
          </StyledDetail>
          <StyledDetail>
            <b>Terminal:</b> {selectedItem?.terminal}
          </StyledDetail>
          <StyledDetail>
            <b>Sub Terminal:</b> {selectedItem?.subTerminal}
          </StyledDetail>
        </StyledContainer>
      )}
    </Modal>
  );
};

export default ConfirmDeleteMapping;
