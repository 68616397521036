import React from 'react';

import GtoMainComp from '../pages/gto/GtoMainComp';
import IAppRoute from './AppRoute.type';
import routePaths from './routepaths';

// import GtoSettings from '../pages/gto/GtoSettings';
// import GTOAttachments from '../pages/gto/GTOAttachments';

// {
//   path: routePaths.gtoSettings,
//   element: <GtoMainComp type='gtoSettings' />,
// },

const GTOLogRoutes: IAppRoute[] = [
  {
    path: routePaths.addAttachments,
    element: <GtoMainComp type='gtoAttachments' />,
  },
  {
    path: routePaths.gtoLogs,
    element: <GtoMainComp type='gtoLogs' />,
  },
];

export default GTOLogRoutes;
