import React from 'react';

import UserAdminAddUserWithRollComp from '../pages/UserAdministration/AddUserWithRole';
import UserAdminApplicationComp from '../pages/UserAdministration/UserAdminApplication';
import UserAdminRoleConfigComp from '../pages/UserAdministration/UserAdminRoleConfig';
import UserAdminUserComp from '../pages/UserAdministration/UserAdminUser';
import IAppRoute from './AppRoute.type';
import routepaths from './routepaths';

const UserAdministrationRoutes: IAppRoute[] = [
  {
    path: routepaths.userAdministrationApp,
    element: <UserAdminApplicationComp />,
  },
  {
    path: routepaths.userAdministrationRoles,
    element: <UserAdminRoleConfigComp />,
  },
  {
    path: routepaths.userAdministrationUser,
    element: <UserAdminUserComp />,
  },
  {
    path: routepaths.userAdministrationAddUser,
    element: <UserAdminAddUserWithRollComp />,
  },
];

export default UserAdministrationRoutes;
